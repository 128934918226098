import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import logo from "../assest/deevapayon_logo.png";

const PoweredBy = () => {
  return (
    <Box
      sx={{
        py: { xs: 6, md: 8 },
        px: { xs: 3, md: 5 },
        borderRadius: 3,
        textAlign: 'center',
        position: 'relative',
        background: 'linear-gradient(135deg, #f9f9fc, #ddeeff)',
        overflow: 'hidden',
      }}
    >
      {/* Animated Background Shapes */}
      <Box
        sx={{
          position: 'absolute',
          top: '-15%',
          left: '-20%',
          width: '400px',
          height: '400px',
          background: 'radial-gradient(circle, #aed7f8, transparent)',
          filter: 'blur(100px)',
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: '-15%',
          right: '-20%',
          width: '500px',
          height: '500px',
          background: 'radial-gradient(circle, #c5e2fa, transparent)',
          filter: 'blur(120px)',
          zIndex: 0,
        }}
      />

      {/* Heading */}
      <Typography
        variant="h4"
        sx={{
          mb: 4,
          fontWeight: 'bold',
          color: '#0d47a1',
          fontSize: { xs: '2rem', md: '2.8rem' },
          textShadow: '2px 2px 5px rgba(0, 0, 0, 0.15)',
          zIndex: 1,
          position: 'relative',
        }}
      >
        Our Achievements
      </Typography>

      {/* Achievements Grid */}
      <Grid container spacing={4} justifyContent="center" sx={{ mb: { xs: 5, md: 8 }, position: 'relative', zIndex: 1 }}>
        {[
          { value: '5+', label: 'Years Experience' },
          { value: '1200+', label: 'Satisfied Clients' },
          { value: '950+', label: 'Active Softwares' },
          { value: '250+', label: 'Active Applications' },
          { value: '4.5', label: 'Clients Rating' },
          { value: '100+', label: 'Partners' },
          { value: '59+', label: 'Experts Team' },
        ].map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box
              sx={{
                textAlign: 'center',
                py: 4,
                px: 3,
                borderRadius: 3,
                background: 'linear-gradient(145deg, #ffffff, #f2f6fb)',
                boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.5s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 12px 40px rgba(0, 0, 0, 0.25)',
                  background: 'linear-gradient(145deg, #0288d1, #03a9f4)',
                },
                '&:hover .card-number': {
                  color: '#fff',
                },
                '&:hover .card-label': {
                  color: '#e0e0e0',
                },
              }}
            >
              <Typography
                variant="h5"
                className="card-number"
                sx={{
                  fontWeight: 'bold',
                  color: '#ff6f61',
                  fontSize: { xs: '2rem', md: '2.4rem' },
                  mb: 1,
                  transition: 'color 0.3s ease',
                }}
              >
                {item.value}
              </Typography>
              <Typography
                variant="subtitle1"
                className="card-label"
                sx={{
                  color: '#333',
                  fontSize: { xs: '1rem', md: '1.2rem' },
                  transition: 'color 0.3s ease',
                }}
              >
                {item.label}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Powered By Section */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          letterSpacing: 2,
          color: '#0d47a1',
          textShadow: '1px 1px 3px rgba(0, 0, 0, 0.2)',
          mb: 3,
          fontSize: { xs: '1.8rem', md: '2.2rem' },
          zIndex: 1,
          position: 'relative',
        }}
      >
        POWERED BY
      </Typography>

      {/* Logo with Animations */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          zIndex: 1,
          '&:hover img': {
            transform: 'rotate(360deg)',
          },
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            width: '150px',
            height: '150px',
            transition: 'transform 1.5s ease',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '-15%',
            left: '-15%',
            width: '180px',
            height: '180px',
            borderRadius: '50%',
            background: 'radial-gradient(circle, rgba(3,169,244,0.2), transparent)',
            animation: 'pulse 2.5s infinite',
          }}
        />
      </Box>

      {/* Pulse Animation */}
      <style>
        {`
          @keyframes pulse {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.3);
            }
            100% {
              transform: scale(1);
            }
          }
        `}
      </style>
    </Box>
  );
};

export default PoweredBy;

