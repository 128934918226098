// src/components/Services.js
import React from 'react';
import { Grid, Card, CardContent, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { Business, PhoneAndroid, Devices, DesignServices, AccountBalance, Cloud, Storage, ShoppingCart, Code, Api, CodeOff } from '@mui/icons-material';

const services = [
  {
    icon: <Business fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'B2B Software Solutions',
    description: 'Robust software solutions to enhance your business processes.',
    path: '/services/b2bsoftware',
  },
  {
    icon: <PhoneAndroid fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'Android Application Development',
    description: 'Custom Android apps to meet your business and customer needs.',
    path: '/services/andriodapp',
  },
  {
    icon: <Devices fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'Website Development',
    description: 'Stunning websites built with the latest technologies for optimal performance.',
    path: '/services/websitedevelopment',
  },
  {
    icon: <DesignServices fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'Graphic Design',
    description: 'Creative designs to captivate your audience and boost engagement.',
    path: '/services/graphic-design',
  },
  {
    icon: <AccountBalance fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'Fintech Software Development',
    description: 'Innovative financial technology solutions for the modern enterprise.',
    path: '/services/fintechsoftweredevelopment',
  },
  {
    icon: <Cloud fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'Cloud Solutions',
    description: 'Scalable and secure cloud computing services for your business needs.',
    path: '/services/cloudsolutions',
  },
  {
    icon: <Storage fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'CRM Solutions',
    description: 'Customer Relationship Management systems to streamline your operations.',
    path: '/services/crmsolutions',
  },
  {
    icon: <ShoppingCart fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'E-Commerce Services',
    description: 'Complete e-commerce solutions to take your online store to the next level.',
    path: '/services/E-CommerceServices',
  },
  {
    icon: <Code fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'Salesforce Services',
    description: 'Tailored Salesforce development to optimize your customer interactions.',
    path: '/services/SalesforceServices',
  },
  {
    icon: <CodeOff fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'Software Development',
    description: 'End-to-end software development services for businesses of all sizes.',
    path: '/services/softwaredevelopment',
  },
  {
    icon: <Code fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'Digital Marketing',
    description: 'Digital marketing strategies to grow your business effectively.',
    path: '/services/digitalmarketing',
  },
  {
    icon: <Api fontSize="large" sx={{ color: '#1976d2' }} />,
    title: 'API Services',
    description: 'API services designed to help businesses integrate innovative solutions seamlessly.',
    path: '/services/apiservices',
  },
];

const Services = () => {
  return (
    <Box id="services-section" sx={{ padding: '4rem 0', backgroundColor: '#f5f5f5', width: '100%' }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#1976d2' }}>
        Our Services
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary" paragraph>
        We offer a wide range of innovative services to meet all your business needs.
      </Typography>
      <Box sx={{ width: '90%', margin: '0 auto' }}>
        <Grid container spacing={4} justifyContent="center">
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  transition: '0.3s',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
                    background: 'linear-gradient(145deg, #f0f0f0, #ffffff)',
                  },
                  padding: '1.5rem',
                  background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
                  borderRadius: '12px',
                }}
              >
                <CardContent sx={{ textAlign: 'center' }}>
                  <Box sx={{ mb: 2 }}>
                    {service.icon}
                  </Box>
                  <Typography variant="h6" sx={{ margin: '1rem 0', fontWeight: 'bold', color: '#333' }}>
                    {service.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {service.description}
                  </Typography>
                  <Button
                    component={Link}
                    to={service.path}
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: '1rem', textTransform: 'none' }}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Services;
